import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'polishDatePipe',
})
export class PolishDatePipe implements PipeTransform {
  transform(value: string | Date): string {
    const date = new Date(value);
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'Europe/Warsaw',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Intl.DateTimeFormat('pl-PL', options).format(date);
  }
}
